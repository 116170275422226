import React, { Component } from 'react'
import Van from '../images/alpine-air-van.jpg'

export default class About extends Component {
  render() {
    return (
      <div className="container about">
        <h1>Alpine Air Tauranga</h1>
        <h2>Automotive Air Conditioning Specialists</h2>
        <div className="columns">
          <div className="column">
            <div className="description">
              <p>
                For mobile automotive air conditioning servicing and repairs in
                <strong>Tauranga, Mount Maunganui, <a href="/katikati">Katikati</a>, <a href="/waihi">Waihi</a></strong> &amp; <strong><a href="/tepuke">Te Puke</a></strong> Alpine Air has been the trusted name since 2000.
              </p>
              <p>
                Call the team on <strong>022 309 7494</strong>
                <br />
                Email us on <strong>alpineairtauranga@gmail.com</strong>
              </p>
              <p>
                We service the following vehicles
                <br />
                <strong>
                  Cars, Trucks, Agricultural and Earth Moving Equipment
                </strong>
              </p>
              <p>
                We offer the following services
                <br />
                <strong>
                  Re-Gassing,
                  <br />
                  Heating/Cooling Repairs,
                  <br />
                  Preventative Maintenance/Annual Servicing,
                  <br />
                  Windscreen Demisting Efficiency,
                  <br />
                  Odour Elimination,
                  <br />
                  Cabin/Pollen Filter Replacement
                </strong>
              </p>
            </div>
          </div>
          <div className="column">
            <img src={Van} alt="Alpine Air Van" />
          </div>
        </div>
      </div>
    )
  }
}
