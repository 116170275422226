import React, { Component } from 'react'

export default class Pricing extends Component {
  render() {
    return (
      <div className="container pricing">
        <h2>Contact Us</h2>
        <div className="description">
          <p>
            Alpine Air Tauranga is the trusted choice for all automotive air
            conditioning repairs and servicing. Our experienced team will look
            after you, providing you with the best in service for a great price.
          </p>
          <p>
            Give us a call on <strong>022 309 7494</strong> or an email at{' '}
            <strong>alpineairtauranga@gmail.com</strong> and we can arrange a
            time to carry out an inspection of your air conditioning unit and
            provide you with an appraisal
          </p>
        </div>
      </div>
    )
  }
}
