import React from 'react'

import Layout from '../components/layout'
import About from '../components/about'
import Pricing from '../components/pricing'
import OpeningHours from '../components/opening-hours'
import Directions from '../components/directions'

const IndexPage = () => (
    <Layout>
        <About />
        <Pricing />
        <OpeningHours />
        <Directions />
    </Layout>
)

export default IndexPage
